<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Contact</span>
        </div>
      </div>

      <div class="hcard relative z-10 p-7">
        <!-- <div>Contact</div> -->
        <div class="grid sm:grid-cols-3 gap-x-3 gap-y-5 mt-2" v-if="user.supports">
          <div class="relative" v-for="u in user.supports.data" :key="u.id">
            <div class="flex">
              <div class="mr-2">
                <IconPIC class="w-15 h-15" />
              </div>

              <div class="grid-cols-1">
                <div class="font-semibold text-black text-lg">{{ u.staff_name }}</div>
                <div class="text-black text-sm">{{ u.staff_position }}</div>
                <div class="text-black text-sm">
                  <a :href="'https://wa.me/' + phoneReplace(u.phone_number)" class="flex space-x-1">
                    <img :src="IconWa" class="mr-2" />
                    {{ phoneReplace(u.phone_number) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconPIC from '@/assets/icons/icon-picTicket.svg?inline'
import IconWa from '@/assets/icons/wa-icon.svg'

import { mapState } from 'vuex'

export default {
  name: 'contact',
  data() {
    return {
      IconWa
    }
  },
  components: {
    IconArrow,
    IconPIC
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    phoneReplace(text) {
      let k = text.replace(/\s/g, '').replaceAll('-', '')
      if (String(k[0]) === '0') {
        let halo = k.replace(k[0], 62)
        return halo
      } else {
        return k
      }
    }
  }
}
</script>
